import { Maybe } from 'graphql/jsutils/Maybe'
import type { NextPage } from 'next'
import Head from 'next/head'
import { paths } from '@/constants'
import { LandingProvider } from '@/contexts/LandingContext'
import { RegionContextProvider } from '@/contexts/RegionContext'
import { ThemeContextProvider } from '@/contexts/ThemeContext'
import { Seo } from '@/layout/Seo'
import { MarketingVideoPopup } from '@/organisms/MarketingVideoPopup'
import { getCollection, GUILD_MEMBERSHIP_NUMBERS_ID, WATCH_FOR_FREE_ENTRY_ID } from '@/services/CmsService/Collection'
import { getPageByPath } from '@/services/CmsService/Pages'
import { getGuildHomeHero } from '@/services/LandingPageService'
import { fetchPageDataContext, PageDataContext, PageModel } from '@/services/RenderService'
import { Collection, GuildHomeHero, InvestmentBanner } from '@/types/codegen-contentful'
import { getGenericStaticPaths } from '@/utils/GetGenericStaticPaths'
import { getStaticPropsErrorHandler } from '@/utils/nextUtils/nextErrorHandlers'
import { determinePageRevalidation } from '@/utils/nextUtils/revalidation'
import { getBasePath } from '@/utils/sitemap/base'
import { useTranslate } from '@/utils/translate/translate-client'
import { loadTranslations } from '@/utils/translate/translate-server'
import { LandingView } from '@/views/LandingView'

interface HomeProps {
  locale: string
  preview: boolean
  guildHomeHero: GuildHomeHero
  investmentBanner?: InvestmentBanner
  watchForFree: Collection
  members?: number
  lastUpdated?: string
  pageDataContext: Maybe<PageDataContext>
  page: Maybe<PageModel>
}

const Home: NextPage<HomeProps> = ({
  locale,
  preview,
  guildHomeHero,
  investmentBanner,
  watchForFree,
  members,
  lastUpdated,
  pageDataContext,
  page,
}) => {
  const { t } = useTranslate('home')
  const url = `${getBasePath(locale)}`
  const title = t('angelStudiosHomeMetaTitle', 'Angel Studios: Stream Free, Original Shows for the Whole Family')
  const description = t(
    'angelStudiosHomeMetaDescription',
    'Get free unlimited access to film and TV series that amplify light through high quality fan-owned entertainment.',
  )

  return (
    <>
      <Seo canonical={url} description={description} openGraph={{ title, description, url }} path={''} title={title} />
      <Head>
        <meta name="insight-app-sec-validation" content="051f0772-9f39-4da7-b0e6-e47292cf6293"></meta>
      </Head>
      <LandingProvider
        guildHomeHero={guildHomeHero}
        watchForFree={watchForFree}
        members={members}
        lastUpdated={lastUpdated}
      >
        <ThemeContextProvider isDarkMode>
          <RegionContextProvider>
            <MarketingVideoPopup />
            <LandingView
              investmentBanner={investmentBanner}
              isLoggedIn={false}
              preview={preview}
              pageDataContext={pageDataContext}
              page={page}
            />
          </RegionContextProvider>
        </ThemeContextProvider>
      </LandingProvider>
    </>
  )
}

export const getStaticPaths = getGenericStaticPaths

export const getStaticProps = getStaticPropsErrorHandler(
  { filePath: 'pages/[locale]/index.tsx' },
  async ({ locale, preview }) => {
    const guildHomeHero = await getGuildHomeHero()
    const investmentBanner = null // until next offering
    const watchForFree = await getCollection({ locale, preview }, WATCH_FOR_FREE_ENTRY_ID)

    const guildMembershipNumbers = await getCollection({ locale, preview }, GUILD_MEMBERSHIP_NUMBERS_ID)
    const members = Number(guildMembershipNumbers?.customData[0]?.numSubscribers.replace(/,/g, '')) ?? null
    const lastUpdated = guildMembershipNumbers?.customData[0]?.updatedAt ?? null

    const page = await getPageByPath(paths.index, { locale, preview })
    const pageDataContext = page ? await fetchPageDataContext(page, preview, locale) : null

    return {
      props: {
        locale,
        preview,
        guildHomeHero,
        investmentBanner,
        watchForFree,
        page,
        pageDataContext,
        members,
        lastUpdated,
        ...(await loadTranslations(locale, ['common', 'home', 'app-promo', 'account', 'guild', 'watch'])),
      },
      revalidate: determinePageRevalidation(page, pageDataContext),
    }
  },
)

export default Home
